import { ifProp } from 'styled-tools';
import styled from '@emotion/styled';
import { OptionProps } from './components/Option';
import { StyledInputField } from '../InputField';

type StyledOptionProps = Pick<OptionProps, 'selected' | 'disabled'>;

export const StyledOption = styled.li<StyledOptionProps>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spaces[3],
        listStyle: 'none',
        padding: `${theme.spaces[3]} ${theme.spaces[4]}`,
        cursor: 'pointer',
    }),
    ifProp('selected', ({ theme }) => ({
        backgroundColor: theme.colors.formFieldActiveBackground,
    })),
    ifProp('disabled', {
        opacity: 0.6,
    })
);

export const StyledOptions = styled.ul(({ theme }) => ({
    padding: 0,
    margin: 0,
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    backgroundColor: theme.colors.formFieldBackground,
    border: `2px solid ${theme.colors.light50}`,
    color: theme.colors.formFieldText,
    borderTop: 'none',
    boxSizing: 'border-box',
    zIndex: 4,
    '&:empty': {
        border: 'none',
    },
}));

export const StyledSelector = styled.div({
    [`${StyledInputField}, input`]: {
        cursor: 'pointer',
    },
});

export const StyledIcon = styled.span<{ isOpen: boolean }>(({ isOpen, theme }) => ({
    transition: '0.1s ease-in-out',
    transform: isOpen ? 'rotate(180deg)' : '',
    lineHeight: 0,
    color: theme.colors.formFieldText,
}));
